<template>
  <div class="wrapper" id="slide-5">
    <div class="storys-slider">
      <div class="storys-slider__title">
        <h1 class="slide-text__title" data-aos="fade">
          Интересные истории из жизни геологов
        </h1>
        <div class="storys-slider__quotes">
          Почитайте реальные истории, которые прислали нам люди, работающие в
          сфере геологии
        </div>
      </div>
      <swiper
        @slideChange="onAfterChange"
        :options="{ ...options, initialSlide }"
        ref="storySlider"
        class="storys-slider__slider"
        data-aos="fade"
      >
        <swiper-slide
          v-for="(story, index) in storys"
          :key="index"
          class="slide-story"
        >
          <div class="slide-photo" >
            <swiper
              class="slide-photo__slider"
              :options="{ ...imageSliderOptions }"
            >
              <swiper-slide
                v-for="(img, idx) in story.imgs"
                :key="`${index}-${idx}`"
              >
                <img :src="img" />
              </swiper-slide>
              <button
                class="storys-photo-next"
                slot="button-next"
                v-if="story.imgs.length > 1"
              >
                <svg
                  width="8"
                  height="15"
                  viewBox="0 0 8 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.844726 1.23833L6.4643 7.32612L0.844727 13.4141"
                    stroke="white"
                    stroke-width="2"
                  />
                </svg>
              </button>
              <button
                class="storys-photo-prev"
                slot="button-prev"
                v-if="story.imgs.length > 1"
              >
                <svg
                  width="8"
                  height="15"
                  viewBox="0 0 8 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.844726 1.23833L6.4643 7.32612L0.844727 13.4141"
                    stroke="white"
                    stroke-width="2"
                  />
                </svg>
              </button>
            </swiper>
          </div>
          <div class="slide-text">
            <div class="slide-text__position">
              <img
                class="slide-text__icon"
                :src="story.positionIcon"
              />
              <div class="slide-text__name" >
                <h2>{{ story.name }}</h2>
              </div>
              <div
                class="slide-text__likes"
                @click="setLike(story)"
                :class="{ active: !!userLikes.includes(story.slug) }"
              >
                <span>{{ totalLikes[story.slug] || 0 }}</span>
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.12274 3.75486L9.99997 5.35804L10.8772 3.75489C11.1691 3.22151 11.6488 2.52116 12.342 1.9851L11.7302 1.19406L12.342 1.9851C13.0479 1.43913 13.8288 1.17188 14.6875 1.17188C17.0889 1.17188 19 3.12332 19 5.93414C19 7.40101 18.4249 8.67687 17.2662 10.0749C16.0823 11.5036 14.3686 12.9683 12.189 14.8257L12.189 14.8257L12.188 14.8266C11.5254 15.3912 10.7792 16.0272 10 16.7055C9.22137 16.0276 8.47552 15.392 7.81368 14.828L7.81148 14.8261L7.81146 14.8261C5.63164 12.9685 3.91783 11.5037 2.73381 10.075C1.57514 8.67686 1 7.40101 1 5.93414C1 3.12332 2.91115 1.17188 5.3125 1.17188C6.17124 1.17188 6.95209 1.43913 7.65803 1.9851L8.26837 1.19592L7.65803 1.9851C8.35119 2.52117 8.83089 3.22149 9.12274 3.75486Z"
                    stroke="#E4AA00"
                    stroke-width="2"
                  />
                </svg>
              </div>
            </div>
            <div class="slide-text__descr"  @mousewheel.stop>
              <span class="desc_quotes">«</span><span v-html="story.text"></span><span class="desc_quotes">»</span>
            </div>
            <div class="slide-text__share">
              <div class="slide-text__share-title">
                Поделиться в соц. сетях:
              </div>
              <yandex-share
                :services="[
                  'vkontakte',
                  // 'facebook',
                  'twitter',
                  'odnoklassniki',
                ]"
                :copy="'extraItem'"
                :title="`Геологи рассказывают`"
                :description="`Реальные истории реальных геологов`"
                :url="`${currentDomain}/storyshare.php?slide=5&story=${index}`"
                image="https://geolog.rosgeo.com/preview.png"
                data-shape="round"
              />
            </div>
          </div>
        </swiper-slide>
        <button
          class="storys-button-prev"
          slot="button-prev"
          v-show="currentStory > 0"
        >
          <span class="arrow">
            <svg
              width="9"
              height="15"
              viewBox="0 0 9 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.08597 1.39759L1.40241 7.41271L8.08594 13.428"
                stroke="#C8C8C8"
                stroke-width="1.6"
              />
            </svg>
          </span>
        </button>
        <button class="storys-button-next" slot="button-next" v-show="currentStory < storys.length - 1">
          <p>Следующая история</p>
          <span class="arrow"></span>
        </button>
      </swiper>
    </div>
  </div>
</template>

<script>
import YandexShare from "@cookieseater/vue-yandex-share";
import { getQueryStringValue } from "@/helpers/functions";
import { Swiper as SwiperClass } from "swiper/js/swiper.esm";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import axios from "axios";
import "swiper/css/swiper.css";

const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);
export default {
  name: "FourthSlide",
  components: { YandexShare, Swiper, SwiperSlide },
  created() {
    let storyIdx = getQueryStringValue("story");
    storyIdx = parseInt(storyIdx, 10);
    if (Number.isNaN(storyIdx)) storyIdx = 0;
    this.initialSlide = storyIdx;
    this.currentStory = this.initialSlide;
    this.fetchLikes();
  },
  data() {
    return {
      userLikes: [],
      totalLikes: {},
      currentStory: 0,
      initialSlide: 0,
      imageSliderOptions: {
        spaceBetween: 20,
        slidesPerView: "auto",
        centerMode: true,
        pagination: false,
        dots: false,
        navigation: {
          nextEl: ".storys-photo-next",
          prevEl: ".storys-photo-prev",
        },
      },
      options: {
        spaceBetween: 20,
        slideToShow: 1,
        dots: false,
        navigation: {
          nextEl: ".storys-button-next",
          prevEl: ".storys-button-prev",
        },
      },
      storys: require("./storys.js"),
    };
  },
  methods: {
    async fetchLikes() {
      try {
        let fetchRoute = "/likes.api.php";
        if (process.env.NODE_ENV === "development") {
          fetchRoute = "https://geolog.faktura.im/likes.api.php";
        }
        const { data } = await axios.get(fetchRoute);
        this.totalLikes = data.totalLikes || {};
        this.userLikes = data.userLikes || {};
      } catch (err) {
        console.log(err);
      }
    },
    async setLike(story) {
      let fetchRoute = "/likes.api.php";
      if (process.env.NODE_ENV === "development") {
        fetchRoute = "https://geolog.faktura.im/likes.api.php";
      }
      const formData = new FormData();
      formData.append("slug", story.slug);
      if (this.userLikes.includes(story.slug)) {
        formData.append("like", 0);
      } else {
        formData.append("like", 1);
      }
      await axios.post(fetchRoute, formData);
      await this.fetchLikes();
    },
    onAfterChange() {
      this.currentStory = this.$refs.storySlider.$swiper.activeIndex;
      console.log(this.currentStory);
    },
  },
  computed: {
    currentDomain() {
      return window.location.origin;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/pages/storys-slide.scss";
</style>
