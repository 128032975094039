import { render, staticRenderFns } from "./FirstSlide.vue?vue&type=template&id=0f028943&scoped=true&"
import script from "./FirstSlide.vue?vue&type=script&lang=js&"
export * from "./FirstSlide.vue?vue&type=script&lang=js&"
import style0 from "./FirstSlide.vue?vue&type=style&index=0&id=0f028943&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f028943",
  null
  
)

export default component.exports