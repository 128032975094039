<template>
  <div class="wrapper">
    <div class="partners-content">
      <h1 class="title-partners" data-aos="fade">
      Партнеры
      </h1>
      <div class="partners-slider" data-aos="fade-up">
        <agile :infinite="true" :options="options">
          <div class="partner-slide" v-for="(partner, index) in partners" :key="index">
            <div class="partner-slide__wrapper">
              <img :src="partner.logo">
            </div>
          </div>
          <template slot="nextButton">
            <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.844726 1.23833L6.4643 7.32612L0.844727 13.4141" stroke="white" stroke-width="2"/>
            </svg>
          </template>
          <template slot="prevButton">
            <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.844726 1.23833L6.4643 7.32612L0.844727 13.4141" stroke="white" stroke-width="2"/>
            </svg>
          </template>
        </agile>
      </div>
    </div>
    <!-- <h2 class="title-supporters" data-aos="fade">
      При поддержке:
    </h2>
    <div class="partners-slider" data-aos="fade-up">
      <agile :options="options">
        <div class="partner-slide" v-for="(partner, index) in partners" :key="index">
          <div class="partner-slide__wrapper">
            <img :src="partner.logo">
          </div>
        </div>
        <template slot="nextButton">
          <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.844726 1.23833L6.4643 7.32612L0.844727 13.4141" stroke="white" stroke-width="2"/>
          </svg>
        </template>
        <template slot="prevButton">
          <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.844726 1.23833L6.4643 7.32612L0.844727 13.4141" stroke="white" stroke-width="2"/>
          </svg>
        </template>
      </agile>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'SeveSlide',
  data() {
    return {
      options: {
        dots: false,
        slidesToShow: 2,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 2.5,
            },
          },
        ],
      },
      partners: [
        {
          logo: require('@/assets/img/partners/5.png'),
        },
        {
          logo: require('@/assets/img/partners/1.png'),
        },
        {
          logo: require('@/assets/img/partners/2.png'),
        },
        {
          logo: require('@/assets/img/partners/3.png'),
        },
        {
          logo: require('@/assets/img/partners/4.png'),
        },
        {
          logo: require('@/assets/img/partners/6.png'),
        },
        {
          logo: require('@/assets/img/partners/7.jpeg'),
        },
        {
          logo: require('@/assets/img/partners/8.jpg'),
        },
        {
          logo: require('@/assets/img/partners/9.jpeg'),
        },
        // {
        //   logo: require('../assets/img/blue-logo.svg'),
        // },
        // {
        //   // logo: require('../assets/img/purple-logo.svg'),
        // },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/styles/pages/seventh-slide.scss';
</style>
