<template>
  <div class="wrapper" id="slide-test">
    <div class="test-wrapper">
      <h1 class="test-title">Тест</h1>
      <div
        v-show="index === questionIndex"
        v-for="(quest, index) in test"
        :key="index"
        class="test-form-wrapper"
        data-aos="fade-down-right"
      >
        <div class="test-number">
          Вопрос {{ index + 1 }} / {{ test.length }}
        </div>
        <div class="test">
          <div class="question-icon">
            <div class="question-icon__border"></div>
            <a href="#" class="question-icon__link">?</a>
          </div>
          <h3 class="test__title">{{ quest.question }}</h3>
          <div class="test__img">
            <img :src="quest.picture" />
          </div>
          <p :style="`opacity: ${testError ? '1' : '0'}`" class="test__error">
            Вы не выбрали ни одного значения
          </p>
          <form action="" name="test__form" class="test__form">
            <div
              class="test__form-group"
              v-for="(answer, i) in quest.answers"
              :key="i"
            >
              <input
                v-model="userAnswers[index].answer"
                :name="`question-${index + 1}`"
                :value="answer.name"
                type="radio"
                :id="`question-${index + 1}-${i}`"
              />
              <label :for="`question-${index + 1}-${i}`">{{
                answer.text
              }}</label>
            </div>
          </form>
          <div
            class="test__buttons"
            :style="
              index !== 0
                ? 'justify-content: space-between;'
                : 'justify-content: center;'
            "
          >
            <div
              v-show="index !== 0"
              @click="prevQuestion"
              class="test__button-prev"
            >
              <svg
                width="13"
                height="19"
                viewBox="0 0 13 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 18.001L11.488 9.89672C11.747 9.69656 11.747 9.3056 11.488 9.10544L1 1.00098"
                  stroke="#E4AA00"
                  stroke-width="1.35"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div
              v-if="questionIndex !== test.length - 1"
              @click="nextQuestion"
              class="test__button-next"
            >
              <p>Следующий вопрос</p>
              <svg
                width="13"
                height="19"
                viewBox="0 0 13 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 18.001L11.488 9.89672C11.747 9.69656 11.747 9.3056 11.488 9.10544L1 1.00098"
                  stroke="#E4AA00"
                  stroke-width="1.35"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div v-else @click="checkResult" class="test__button-next">
              <p>Узнать ответ</p>
              <svg
                width="13"
                height="19"
                viewBox="0 0 13 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 18.001L11.488 9.89672C11.747 9.69656 11.747 9.3056 11.488 9.10544L1 1.00098"
                  stroke="#E4AA00"
                  stroke-width="1.35"
                  stroke-linecap="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="test-picture">
          <img :src="quest.picture" />
        </div>
      </div>
      <div v-show="questionIndex === test.length" class="test-form-wrapper">
        <div class="test test-congratulation">
          <p class="test-congratulation__header">
            Поздравляем, вы ответили на все вопросы!
            <span>Правильных ответов: {{ resultTest }}</span>
          </p>
          <p class="test-congratulation__result">Результат:</p>
          <img
            class="test-congratulation__result-icon"
            :src="resultTestRanks[resultTest].icon"
          />
          <div class="test-congratulation__rank">
            <img src="../assets/img/questions/left-star.svg" />
            <p>{{ resultTestRanks[resultTest].rank }}</p>
            <img src="../assets/img/questions/right-star.svg" />
          </div>
          <div
            v-if="questionIndex === test.length && resultTest === 0"
            @click="resetQuestion"
            class="test__button-reset"
          >
            <svg
              width="13"
              height="19"
              viewBox="0 0 13 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 18.001L11.488 9.89672C11.747 9.69656 11.747 9.3056 11.488 9.10544L1 1.00098"
                stroke="#E4AA00"
                stroke-width="1.35"
                stroke-linecap="round"
              />
            </svg>
            <p>Попробовать снова</p>
          </div>
          <div v-else class="test-congratulation__share">
            <p class="test-congratulation__share-title">
              Поделиться в соц. сетях:
            </p>
            <div class="test-congratulation__share-networks">
              <yandex-share
                v-if="resultTest > 0"
                :services="[
                  'vkontakte',
                  // 'facebook',
                  'twitter',
                  'odnoklassniki',
                ]"
                :title="`Пройди тест и узнай, насколько хорошо ты знаком с геологией!`"
                :description="`Я прошел тест в честь Дня геолога и получил статус ${resultTestRanks[resultTest].rank}`"
                :url="`https://geolog.rosgeo.com/result.php?result=${resultTest}`"
                data-shape="round"
                :image="resultTestRanks[resultTest].img"
              />
            </div>
          </div>
          <div class="test-congratulation__mob-pic">
            <img src="../assets/img/questions/result.png" />
          </div>
        </div>
        <div class="test-picture">
          <img src="../assets/img/questions/result.png" />
        </div>
      </div>
      <div class="test-descr">
        <h2 class="test-descr__title" data-aos="fade">Какой вы геолог</h2>
        <p class="test-descr__text" data-aos="fade">
          Пройдите опрос и узнайте, как хорошо вы разбираетесь в области
          геологии
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import YandexShare from '@cookieseater/vue-yandex-share';

export default {
  name: 'FifthSlide',
  components: {
    YandexShare,
  },
  data() {
    return {
      testError: false,
      resultTest: 0,

      resultTestRanks: [
        {
          rank: 'попробуй еще раз',
          icon: require('../assets/img/questions/icon-result-0.svg'),
          img: `${window.location.origin}/img/questions/icon-result-0.png`,
        },
        {
          rank: 'школьник',
          icon: require('../assets/img/questions/icon-result-1.svg'),
          img: `${window.location.origin}/img/questions/icon-result-1.png`,
        },
        {
          rank: 'студент',
          icon: require('../assets/img/questions/icon-result-2.svg'),
          img: `${window.location.origin}/img/questions/icon-result-2.png`,
        },
        {
          rank: 'юный геолог',
          icon: require('../assets/img/questions/icon-result-3.svg'),
          img: `${window.location.origin}/img/questions/icon-result-3.png`,
        },
        {
          rank: 'бывалый геолог',
          icon: require('../assets/img/questions/icon-result-4.svg'),
          img: `${window.location.origin}/img/questions/icon-result-4.png`,
        },
        {
          rank: 'мудрый геолог',
          icon: require('../assets/img/questions/icon-result-5.svg'),
          img: `${window.location.origin}/img/questions/icon-result-5.png`,
        },
      ],

      questionIndex: 0,
      userAnswers: [
        {
          answer: '',
        },
        {
          answer: '',
        },
        {
          answer: '',
        },
        {
          answer: '',
        },
        {
          answer: '',
        },
      ],
      test: [
        {
          question: 'Как называется это песчаное образование?',
          answers: [
            {
              name: 'banch',
              text: 'Бенч',
            },
            {
              name: 'scythe',
              text: 'Коса',
            },
            {
              name: 'tombolo',
              text: 'Томболо',
            },
          ],
          result: 'tombolo',
          picture: require('../assets/img/questions/picture1.jpg'),
        },
        {
          question: 'Разрывное нарушение какого типа представлено на изображении?',
          answers: [
            {
              name: 'shofr',
              text: 'Сдвиг',
            },
            {
              name: 'discharge',
              text: 'Сброс',
            },
            {
              name: 'uplift',
              text: 'Взброс',
            },
          ],
          result: 'discharge',
          picture: require('../assets/img/questions/picture2.jpg'),
        },
        {
          question: 'Какую рудную нагрузку несут в себе черные курильщики?',
          answers: [
            {
              name: 'Fe',
              text: 'Fe, Cu, Zn, Au',
            },
            {
              name: 'Li',
              text: 'Li, Cs, Ba, Rb',
            },
            {
              name: 'U',
              text: 'U, Th, K',
            },
          ],
          result: 'Fe',
          picture: require('../assets/img/questions/picture3.jpg'),
        },
        {
          question: 'Какое происхождение имеет эта горная порода?',
          answers: [
            {
              name: 'magmatic',
              text: 'Магматическое',
            },
            {
              name: 'metamorphic',
              text: 'Метаморфическое',
            },
            {
              name: 'sedimentary',
              text: 'Осадочное',
            },
          ],
          result: 'sedimentary',
          picture: require('../assets/img/questions/picture4.jpg'),
        },
        {
          question: 'Что может образоваться в отделившемся от моря озере?',
          answers: [
            {
              name: 'sulfur',
              text: 'Сера',
            },
            {
              name: 'travertine',
              text: 'Травертин',
            },
            {
              name: 'salt',
              text: 'Соль',
            },
          ],
          result: 'salt',
          picture: require('../assets/img/questions/picture5.jpg'),
        },
      ],
    };
  },
  methods: {
    setSessionStorage(index) {
      sessionStorage.setItem(index, this.userAnswers[index].answer);
      if (index === 4) {
        sessionStorage.setItem('questionIndex', this.questionIndex);
        sessionStorage.setItem('result', this.resultTest);
      }
    },
    nextQuestion() {
      if (!this.userAnswers[this.questionIndex].answer) {
        this.testError = true;
      } else {
        this.setSessionStorage(this.questionIndex);
        this.questionIndex += 1;
        this.testError = false;
      }
    },
    prevQuestion() {
      this.questionIndex -= 1;
    },
    checkResult() {
      if (!this.userAnswers[this.questionIndex].answer) {
        this.testError = true;
      } else {
        this.resultFilter(this.userAnswers, this.test);
        this.nextQuestion();
        this.$metrika.reachGoal('complete_test');
      }
    },
    resetQuestion() {
      this.resultTest = 0;
      this.questionIndex = 0;
      this.userAnswers.forEach((a) => {
        a.answer = '';
      });
    },
    resultFilter(val1, val2) {
      val1.forEach((item, index) => {
        console.log(item.answer);
        console.log(val2[index].result);
        if (item.answer === val2[index].result) {
          this.resultTest += 1;
        }
      });
    },
  },
  mounted() {
    this.userAnswers.forEach((item, index) => {
      if (sessionStorage.getItem(index)) {
        item.answer = sessionStorage.getItem(index);
      }
    });
    if (sessionStorage.getItem('questionIndex')) {
      this.questionIndex = Number(sessionStorage.getItem('questionIndex')) + 1;
    }
    if (sessionStorage.getItem('result')) {
      // this.resultTest = Number(sessionStorage.getItem('result')) + 1;
      this.test.forEach((row, idx) => {
        if (row.result === this.userAnswers[idx].answer) {
          this.resultTest += 1;
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/pages/fifth-slide.scss";
</style>
