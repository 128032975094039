const historyDates = [
  {
    subTitle: 'НАЧАЛО',
    date: '1491-1592',
    img: require('./img/history/1491-1492.jpg'),
    title: 'Первое упоминание в документах о государственной экспедиции для поиска серебряных и медных руд в Печорском крае',
    text: 'Весной 1491 года Великий князь Московский Иван III отправил в Печорский край экспедицию для поиска серебряных и медных руд – государству остро требовались собственные ресурсы металла для чеканки монет. Через пять лет на Цилемском руднике заработали медеплавильные печи, а в Москве из добытого металла начали чеканить первые чисто российские монеты.',
  },
  {
    subTitle: 'ГОСУДАРЕВ ПРИКАЗ КАМЕННЫХ ДЕЛ',
    date: '1584',
    img: '',
    title: 'Создан Государев приказ каменных дел, который ведал поиском и разведкой руд железа и цветных металлов',
    text: 'В последний год жизни царь Иван Грозный учредил Государев приказ каменных дел, который ведал казенным каменным строительством, поиском, разведкой и добычей строительных материалов, а также руд железа и цветных металлов. В приказ поступали челобитные от рудознатцев, по которым снаряжались экспедиции на поиски рудных месторождений.',
  },
  {
    subTitle: 'ПЕРВЫЙ В РОССИИ МЕДЕПЛАВИЛЬНЫЙ ЗАВОД',
    date: '1633',
    img: require('./img/history/1633.jpg'),
    title: 'Богатые залежи медной руды, найденные у Соли Камской, позволили построить первый в России медеплавильный завод',
    text: 'В 1633 году экспедиция стольника Василия Стрешнева, в поисках золота наткнулась на богатые залежи медной руды «у Григорьевой горы вблизи Соли Камской». Через два года при участии специалистов из Саксонии неподалеку был построен первый в России Пыскорский медеплавильный завод, просуществовавший до начала XIX века.',
  },
  {
    subTitle: 'ПЕРВОЕ В РОССИИ МЕСТОРОЖДЕНИЕ МАГНИТНОГО ЖЕЛЕЗНЯКА',
    date: '1667-1697',
    img: require('./img/history/1667-1697.jpg'),
    title: 'Первое в России месторождение магнитного железняка с содержанием железа до 70% было открыто на Урале у реки Тагил',
    text: 'В 1667 году рудознатец Дмитрий Тумашев на слиянии рек Выя и Тагил на Урале открыл первое месторождение магнитного железняка (магнетита) с содержанием железа до 70%. Однако, в силу обстоятельств, про открытие забыли на 30 лет. В 1697 году, когда Петру I понадобилось хорошее железо, образцы руды с Тагила вновь испытали, и иноземные мастера высоко оценили ее качество.',
  },
  {
    subTitle: 'ПЕРВАЯ ПРОМЫШЛЕННАЯ ДОБЫЧА СЕРЕБРА В РОССИИ',
    date: '1676-1684',
    img: require('./img/history/1676-1684.jpg'),
    title: 'Начало промышленной добычи серебра',
    text: 'Первая промышленная добыча серебра в России началась на Нерчинских рудниках в Забайкалье у границы с Китаем на месте заброшенных копей неизвестного исчезнувшего народа В 1684 году, нерчинский воевода Иван Власов отправил экспедицию, которая нашла старые копи и новую серебряную жилу и привезла в Нерчинск более 60 пудов руды. В 1704 году состоялось открытие Нерчинского сереброплавильного завода.',
  },
  {
    subTitle: 'БЕРГ-КОЛЛЕГИЯ ПЕТРА I',
    date: '1719-1722',
    img: require('./img/history/1719-1722.jpg'),
    title: 'Царь Петр создал Берг-коллегию и объявил свободу горного промысла в России',
    text: 'Согласно указу Петра I от 10 декабря 1719 г. объявлялась полная свобода горного промысла в России, ставшая мощным стимулом для поиска и разработки полезных ископаемых. Было разрешено всем и каждому, какого бы чина и достоинства ни был, во всех местах «искать, копать, плавить, варить и чистить всякие металлы. Первым президентом Берг-коллегии был генерал-фельдмаршал Яков Брюс.',
  },
  {
    subTitle: 'КАМЕННЫЙ УГОЛЬ ДОНБАССА',
    date: '1721-1791',
    img: require('./img/history/1721-1791.jpg'),
    title: 'Каменный уголь Донбасса был открыт из-за нехватки дров для соляного промысла, но его добыча началась только через 70 лет',
    text: 'Нехватка топлива для соляных промыслов в Бахмуте стала стимулом для поиска каменного угля на юге России. В 1721 году комендант Бахмутской крепости Семен Чирков и надсмотрщик солеварен Никита Вепрейский нашли выходы угля в балке Скелеватой и на речке Беленькой. Образцы были доставлены в Берг-коллегию и испытаны английским горным мастером Никсоном, который высоко оценил качество угля',
  },
  {
    subTitle: 'КУЗНЕЦКИЙ УГОЛЬНЫЙ БАССЕЙН',
    date: '1721',
    img: require('./img/history/1721.jpg'),
    title: 'Кузнецкий угольный бассейн был открыт одновременно с Донецким, но ждал начала разработки более 100 лет',
    text: 'Первооткрывателем угля в Кузбассе стал крестьянин Михайло Волков, который в сентябре 1721 года, обнаружил горелую гору «в семи верстах выше Верхотомского острога на правом берегу реки Томь». Только в середине XIX века в Кузбассе появилось первое предприятие по добыче угля, а геолог Петр Чихачев, описывая месторождения, придумал название - Кузнецкий угольный бассейн.',
  },
  {
    subTitle: 'ПЕРВАЯ РОССИЙСКАЯ НЕФТЬ ИЗ КЛЮЧА НА РЕКЕ УХТЕ',
    date: '1721-1724',
    img: require('./img/history/1721-1724.jpg'),
    title: 'Первая российская нефть из ключа на реке Ухте',
    text: 'Первооткрывателем Тимано-Печорской нефти считается рудознатец из Мезени Григорий Черепанов, который в 1721 году «в стране Коми» нашел капельный выход нефти со дна реки Ухты и сообщил о нем в Берг-коллегию. В 1724 году по приказу Петра I Черепанов привез в столицу около 25 литров первой русской нефти, которые были отправлены для исследования химического состава в Голландию.',
  },
  {
    subTitle: 'ПЕРВОЕ ЗОЛОТОРУДНОЕ МЕСТОРОЖДЕНИЕ В РОССИИ',
    date: '1745-1747',
    img: require('./img/history/1745-1747.jpg'),
    title: 'Первое в России золоторудное месторождение',
    text: 'Золоторудное месторождение Берёзовское  в 1745 году на Урале обнаружил крестьянин Ерофей Марков из деревни Шарташ близ Екатеринбурга. В 1748 году заработал Шарташский рудник, а в 1753 году там был построен Берёзовский золотопромывальный завод. К началу XIX века в окрестностях первого рудника было выявлено еще около 70 золоторудных жил. Район стали называть «Екатеринбургской золотой долиной».',
  },
  {
    subTitle: 'СИСТЕМАТИЗАЦИЯ ИНФОРМАЦИИ О ПОЛЕЗНЫХ ИСКОПАЕМЫХ РОССИИ',
    date: '1768-1774',
    img: require('./img/history/1768-1774.jpg'),
    title: 'Научные экспедиции по сбору информации о полезных ископаемых России',
    text: 'В 1768 году после смерти Ломоносова по разработанному им плану в разные районы России Петербургская Академия Наук направила пять научных экспедиций по сбору материалов для составления географических карт и ведения разносторонних наблюдений природы. К 1774 году все академические экспедиции закончили работу и вернулись в Петербург, приступив к обработке и обобщению собранного материала.',
  },
  {
    subTitle: 'УЧРЕЖДЕНИЕ ГОРНОГО УЧИЛИЩА В ПЕТЕРБУРГЕ',
    date: '1773',
    img: require('./img/history/1773.jpg'),
    title: 'Учреждения Горного училища - прародителя Петербургского горного института',
    text: 'В 1770 году пермские рудопромышленники подали в Берг-коллегию предложение учредить в столице специализированное горнотехническое учебное заведение и пообещали отчислять на содержание училища по 1/4 копейки с каждого пуда добытой руды. Екатерина II одобрила проект, и в июне 1774 года состоялось торжественное открытие Горного училища, которое в 1804 году было переименовано в Горный кадетский корпус, а в 1866 году – в Петербургский горный институт.',
  },
  {
    subTitle: 'НАХОДКА ПЕРВОГО В РОССИИ И В ЕВРОПЕ АЛМАЗА',
    date: '1829',
    img: '',
    title: 'Находка первого в России и в Европе алмаза',
    text: 'Летом 1829 г. на западном склоне Урала на Крестовоздвиженских золотых промыслах графа Адольфа Полье во время промывки шлихов сын крестьянина Павел Попов нашел интересный камушек и отдал управляющему - минерологу Федору Шмидту, который определил в камне алмаз в 1,5 карата. Найденный алмаз передали немецкому ученому Александру Гумбольдту, который торжественно преподнес камень императрице Александре Федоровне.',
  },
  {
    subTitle: 'ВЫСШИЙ СОВЕТ НАРОДНОГО ХОЗЯЙСТВА РСФСР',
    date: '1918',
    img: '',
    title: 'Создание первых в РСФСР территориальных геологических служб',
    text: 'В марте 1918 года в составе Высшего Совета Народного Хозяйства РСФСР были созданы 6 отделений: Московское, Украинское, Сибирское, Уральское, Среднеазиатское, Северо-Кавказское, а также Закавказское бюро. Московское отделение регулировало все геологические и геологоразведочные работы на огромной территории Европейской части России за исключением Украины, Северного Кавказа и Урала.',
  },
  {
    subTitle: 'ПЕРВЫЕ АПАТИТЫ НА КОЛЬСКОМ ПОЛУОСТРОВЕ',
    date: '1921',
    img: '',
    title: 'Первые апатиты на Кольском полуострове обнаружила экспедиция Академии наук во главе с Александром Ферсманом ',
    text: 'Летом 1920 года геохимик и минералог Александр Ферсман, недавно ставший академиком, организовал первую экспедицию в Хибинские горы. В 1921 году уже следующая его экспедиция нашла огромные зеленые глыбы апатита. Последующее систематическое изучение Хибин позволило в 1925-27 годах открыть уникальные по запасам и качеству месторождения апатит-нефелиновых руд – ценнейшего фосфатного сырья.',
  },
  {
    subTitle: 'ОТКРЫТИЕ ВЕРХНЕКАМСКОГО МЕСТОРОЖДЕНИЯ КАЛИЙНО-МАГНИЕВЫХ СОЛЕЙ',
    date: '1925',
    img: '',
    title: 'Открытие Верхнекамского месторождения калийно-магниевых солей ',
    text: 'Еще в середине XV века на Каме появились первые солеварни. Рассол качали из земли по выдолбленным стволам деревьев. Только в 1924 году началась полноценная разведка в регионе. В итоге 6 октября 1925 года на глубине около 92 м был обнаружен пласт калийных солей с содержанием KCl 17,9%. Запасы Верхнекамского месторождения по К2О по категориям АВС1+С2 составляют более 30% мировых и 90% разведанных запасов в России.',
  },
  {
    subTitle: 'ГИПОТЕЗА ИВАНА ГУБКИНА О НЕФТЕНОСНОСТИ',
    date: '1929-1932',
    img: '',
    title: 'Открытие нефтяных месторождений Урало-Поволжья',
    text: 'Гипотеза Ивана Губкина о нефтеносности Урало-Волжского района подтвердилась открытиями месторождений в Перми и Башкирии. В апреле 1929 года, под Пермью из скважины, которую бурили на калийную соль, с глубины 325 м ударил нефтяной фонтан. Был создан трест «Уралнефть» и направлены экспедиции в Башкирию. 16 мая 1932 года в Башкирии близ деревни Ишимбаево было открыто Ишимбайское месторождение, положившее начало «второму Баку».',
  },
  {
    subTitle: 'ОТКРЫТИЕ ЛЕГЕНДАРНОГО РОМАШКИНСКОГО МЕСТОРОЖДЕНИЯ НЕФТИ',
    date: '1948',
    img: '',
    title: 'Открытие уникального Ромашкинского месторождения заложило основу нефтяного могущества Советского Союза',
    text: 'В июле 1948 году у деревни Ромашкино республики Татарстан был вскрыт мощный девонский пласт, из которого был получен фонтан дебитом более 120 тонн в сутки. Тогда геологи еще не знали, что открыли одно из крупнейших нефтяных месторождений мира – Ромашкинское. Его геологические запасы превышают 5 млрд тонн, из которых уже добыто более 2,2 млрд, а площадь продуктивных залежей превышает 4500 км2.',
  },
  {
    subTitle: 'ОТКРЫТИЕ КИМБЕРЛИТОВЫХ ТРУБОК В ЯКУТИИ',
    date: '1954-1955',
    img: '',
    title: 'С открытием кимберлитовых трубок в Якутии Россия вошла в число крупнейших производителей алмазов в мире',
    text: 'Первые алмазы в Якутии были обнаружены в 1948-49 в верховьях Нижней Тунгуски и Вилюя. Отряд Натальи Старосадских в 1953 году обнаружил признаки алмазов, а августе 1954 года ее сотрудники геолог Лариса Попугаева и лаборант Федор Беликов на ручье Дьяхе нашли кимберлитовые породы первой алмазоносной трубки «Зарница». Через год геологи Амакинской экспедиции открыли знаменитую трубку «Удачная», которая обеспечивает сегодня около 10% мировой добычи',
  },
  {
    subTitle: 'ЧУКТУКОНСКОЕ МЕСТОРОЖДЕНИЕ РЕДКОЗЕМЕЛЬНЫХ МЕТАЛЛОВ В КРАСНОЯРСКОМ КРАЕ',
    date: '1959',
    img: '',
    title: 'Открытие Чуктуконское месторождение редкоземельных металлов - объекта мирового масштаба',
    text: 'В 1959 г. в Красноярском крае геологи Богучанской аэрогеофизической партии Дашкевич и Стародубов с помощью металлометрической съемки при проверке радиометрических аномалий открыли огромное Чуктуконское ниобий-редкоземельное месторождение. В 2019 году геологи «Сибирского ПГО» завершили масштабные исследования Чуктукона, что позволило увеличить первоначальную оценку запасов месторождения в несколько раз.',
  },
  {
    subTitle: 'СУХОЙ ЛОГ – КРУПНЕЙШЕЕ ЗОЛОТОРУДНОЕ МЕСТОРОЖДЕНИЕ МИРА',
    date: '1961',
    img: require('./img/history/1961.jpg'),
    title: 'Открытие крупнейшего золоторудного месторождения мира – Сухой Лог',
    text: 'В начале 1960-x годов геолог Института земной коры Владимир Буряк, интерпретируя выявленную геохимическую аномалию содержания золота в породах золотокварцевого жильного рудопроявления Сухой Лог, предположил присутствие золотосульфидного оруденения. Первые же поисковые скважины в 1961 году вскрыли золотосодержащую сульфидную минерализацию, подтвердив прогноз Буряка. Это крупнейшее месторождение золота в стране, на которое приходится 28% российских запасов золота.',
  },
  {
    subTitle: 'ОТКРЫТИЕ СТРЕЛЬЦОВСКОГО РУДНОГО ПОЛЯ',
    date: '1963',
    img: '',
    title: 'Открытие Стрельцовского рудного поля в Забайкалье поставило Россию в число лидеров по запасам урана в мире',
    text: 'Активные работы по созданию минерально-сырьевой базы атомной промышленности начались в СССР в 1943 году. В 1945 г. началась добыча первой урановой руды в Таджикистане, в 1948г. - на Украине. Крупнейшее в России Стрельцовское месторождение урана в Восточном Забайкалье открыла в 1963 году партия №324 Сосновской экспедиции, ныне филиал «Сосновгеология» АО «Урангео».',
  },
  {
    subTitle: 'ОТКРЫТИЕ САМОТЛОРА',
    date: '1965',
    img: '',
    title: 'Открытие Самотлора – крупнейшего по запасам нефти месторождения в России ',
    text: 'Месторождение было открыто Мегионской нефтеразведочной экспедицией «Главтюменьгеологии» под руководством Владимира Абазарова. 22 июня 1965 года из поисковой скважины Р-1 бурового мастера Григория Норкина ударил мощный фонтан — 420 тонн нефти в сутки.  Нефтепромысел создавали прямо на озере Самотлор, отсыпая искусственные острова для буровых площадок. С начала разработки добыто более 2,8 млрд тонн нефти.',
  },
  {
    subTitle: 'КОЛЬСКАЯ СВЕРХГЛУБОКАЯ СКВАЖИНА',
    date: '1970-1991',
    img: '',
    title: '12262 м от поверхности Земли – мировой рекорд глубины бурения, установленный Кольской сверхглубокой скважиной ',
    text: 'Бурение Кольской скважины стартовало 24 мая 1970 года в 100 км от Мурманска в городе Заполярный. Место выбрано не случайно: на Кольском полуострове на поверхность выходят древнейшие породы Балтийского щита, возраст которых - около 3 млрд лет. На глубине свыше 9,5 км были обнаружены уникальные залежи редких полезных ископаемых, в частности золота.  Правопреемником НПЦ «Кольская сверхглубокая» сегодня является НПЦ «Недра» АО «Росгео',
  },
  {
    subTitle: 'ВПЕРВЫЕ ОЗВУЧЕНА ИДЕЯ КОНСОЛИДАЦИИ ГЕОЛОГИЧЕСКИХ ОРГАНИЗАЦИЙ РОССИИ',
    date: '2008',
    img: '',
    title: 'Впервые озвучена идея консолидации геологических организаций России',
    text: 'Идея консолидации геологических организаций России для стимулирования частных инвестиций в отрасль была впервые озвучена Министерством природных ресурсов в марте 2008 года. Предлагалось создать госкорпорацию «Геологоразведка» путем объединения около 60 государственных геологических предприятий, подконтрольных Роснедрам и Росимуществу',
  },
  {
    subTitle: 'ОБЪЯВЛЕНИЕ О ПЛАНАХ СОЗДАНИЯ АО «РОСГЕОЛОГИЯ»',
    date: '2009',
    img: '',
    title: 'Объявление о планах создания АО «Росгеология',
    text: 'Официально о планах создания АО «Росгеология» Минприроды России объявило 2 октября 2009 года, в дальнейшем проект был внесен на рассмотрение Правительства. ',
  },
  {
    subTitle: 'УТВЕРЖДЕНА СТРАТЕГИЯ РАЗВИТИЯ ГЕОЛОГИЧЕСКОЙ ОТРАСЛИ В РОССИИ',
    date: '2010',
    img: '',
    title: 'Утверждена стратегия развития геологической отрасли в России',
    text: '21 июня 2010 года Правительство России утвердило «Стратегию развития геологической отрасли в России до 2030 года», которая прямо предусматривала «консолидацию специализированных по видам геолого-разведочных работ и полезных ископаемых геологических организаций в форме акционерного общества» ',
  },
  {
    subTitle: 'СОЗДАНИЕ И РАЗВИТИЕ ГОСУДАРСТВЕННОГО ХОЛДИНГА АО «РОСГЕОЛОГИЯ»',
    date: '2011',
    img: '',
    title: 'Создание и развитие государственного холдинга АО «Росгеология» ',
    text: 'О планах создания «Росгеологии» Минприроды объявило 2 октября 2009 года. В июле 2011 года вышел Указ Президента России о создании «Росгеологии» и соответствующее распоряжение Правительства. В собственность ОАО «Росгеология», сформированного на базе ОАО «Центргеология», в дальнейшем были переданы акции около 60 предприятий и институтов.',
  },
  {
    subTitle: 'РАЗВИТИЕ МЕЖДУНАРОДНОГО НАПРАВЛЕНИЯ',
    date: '2014',
    img: '',
    title: 'Геологоразведка за пределами России включена в сферу деятельности «Росгеологии»',
    text: 'Следующий этап развития «Росгеологии» стартовал в 2014-2015 годах. Указом Президента РФ №103 от 21 февраля 2014 года «О развитии ОАО «Росгеология» в состав холдинга было включено ОАО «Зарубежгеология», которое исторически в рамках межправительственных соглашений выполняло все геологоразведочные работы на территории зарубежных стран, в первую очередь, в Азии и Африке.',
  },
  {
    subTitle: 'РАСШИРЕНИЕ ДЕЯТЕЛЬНОСТИ ХОЛДИНГА',
    date: '2015',
    img: '',
    title: 'Росгеология» усилила позиции в наземной геофизике и морских исследованиях ',
    text: '19 февраля 2015 года Президент России подписал Указы, которые кардинально расширяли состав и специализацию ОАО «Росгеология». В уставной капитал планировалось внести  государственные акции 15 АО и 10 ФГУП после их акционирования. В состав холдинга вводились 8 НИИ, 9 предприятий наземной геофизики и 7 предприятий по морским геологическим исследованиям и производству оборудования для глубоководных работ.',
  },
  {
    subTitle: 'РЕОРГАНИЗАЦИЯ СТРУКТУРЫ ХОЛДИНГА',
    date: '2016 – 2018',
    img: '',
    title: 'Реорганизация структуры Холдинга в области разведки твердых полезных ископаемых',
    text: 'В 2016-2018 годах реализован первый этап реорганизации АО «Росгеология» холдинга, в ходе которого геологоразведочные предприятия, специализирующиеся на ТПИ и гидрогеологии, были объединены по географическому принципу в 6 производственно-геологических объединений – «Центральное ПГО», «Северо-Западное ПГО», «Северо-Кавказское ПГО», «Сибирское ПГО», «Северо-Восточное ПГО» и «Дальневосточное ПГО».',
  },
];

export default historyDates;
