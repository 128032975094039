<template>
  <div class="wrapper" id="slide-2">
    <div class="history-facts-wrapper">
      <div class="history-facts__header">
        <h1 data-aos="fade-left">Геологоразведка в россии</h1>
        <h2 data-aos="fade">{{ slides[currentSlide].date }}</h2>
      </div>
      <div class="history-facts__title">
        <h3>{{ slides[currentSlide].subTitle }}</h3>
        <span class="line"></span>
        <p>{{ slides[currentSlide].title }}</p>
      </div>
      <div class="history-facts__body" data-aos="fade">
        <img :src="require('../assets/img/history/iconka.png')">
        <p >{{ slides[currentSlide].text }}</p>
      </div>
    </div>
    <div class="nav-history-slider-wrapper">
      <p class="slider-title">щелкните на дату, чтобы открыть другой год</p>
      <div class="history-slider">
        <agile ref="historySlider" :options="options" class="slider">
          <div v-for="(slide, index) in slides" :key="index">
            <div class="history-slide__wrapper" @click="goToIndex(index);">
              <span class="arrow">
                <div class="arrow__circle-wrapper" :id="'nav-item' + index">
                  <div class="arrow__circle-border">
                    <div class="arrow__circle"></div>
                  </div>
                </div>
              </span>
              <span class="date">{{ slide.date }}</span>
            </div>
          </div>
        </agile>
        <div class="prevArrow" @click="prevBtn">
          <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.999973 1.00024L7.1111 6.50017L1 12.0002" stroke="#E1BD00" stroke-width="1.3"/>
          </svg>
          <span class="prevArrowLine"></span>
        </div>
        <div class="nextArrow">
          <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.999973 1.00024L7.1111 6.50017L1 12.0002" stroke="#E1BD00" stroke-width="1.3"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import historyDates from '../assets/history';

export default {
  name: 'SecondSlide',
  methods: {
    addActiveClass(i) {
      document.querySelector('.agile__slides--regular').querySelectorAll('.arrow__circle-wrapper').forEach((item) => {
        item.classList.remove('active-slide');
      });
      document.querySelector('.agile__slides--regular').querySelector(`#nav-item${i}`).classList.add('active-slide');
    },
    goToIndex(i) {
      this.currentSlide = i;
      if (this.slides.length - this.$refs.historySlider.getCurrentSettings().slidesToShow >= i) {
        this.$refs.historySlider.goTo(i);
        this.addActiveClass(i);
      } else {
        this.$refs.historySlider.goTo(this.slides.length - this.$refs.historySlider.getCurrentSettings().slidesToShow);
        this.addActiveClass(i);
      }
    },
    prevBtn() {
      if (this.$refs.historySlider.getCurrentSlide() > this.$refs.historySlider.getCurrentSettings().slidesToShow) {
        this.$refs.historySlider.goTo(this.$refs.historySlider.getCurrentSlide() - this.$refs.historySlider.getCurrentSettings().slidesToShow);
        this.addActiveClass(this.$refs.historySlider.getCurrentSlide());
      } else {
        this.$refs.historySlider.goTo(0);
        this.addActiveClass(this.$refs.historySlider.getCurrentSlide());
      }
      this.currentSlide = this.$refs.historySlider.getCurrentSlide();
    },
  },
  mounted() {
    document.querySelector('.agile__slides--regular').querySelectorAll('.arrow__circle-wrapper')[0].classList.add('active-slide');
  },
  data() {
    return {
      options: {
        slidesToShow: 7,
        dots: false,
        infinite: false,
        navButtons: false,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 7,
            },
          },
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      },
      slides: historyDates,
      currentSlide: 0,
    };
  },

};
</script>

<style lang="scss" scoped>
@import '../assets/styles/pages/second-slide.scss';
</style>
