<template>
  <div class="nav-wrapper">
    <div class="nav" ref="navigation">
      <div
        class="nav-item"
        :class="[currentSlide === 'slide' + index ? 'nav-item-active' : '']"
        v-for="index in quantitySlides"
        :key="index"
        :data-slide="index"
        @click="changeSlide">
        <svg :viewBox="[currentSlide === 'slide' + index ? '0 0 10 10' : '0 0 6 6']"
          fill="none"
          :width="[currentSlide === 'slide' + index ? '10' : '6']"
          :height="[currentSlide === 'slide' + index ? '10' : '6']"
          xmlns="http://www.w3.org/2000/svg">
          <path
          :d="[currentSlide === 'slide' + index ? 'M5 0L9.33013 2.5V7.5L5 10L0.669873 7.5V2.5L5 0Z':
          'M3 0L5.59808 1.5V4.5L3 6L0.401924 4.5V1.5L3 0Z']"
          :fill="[currentSlide === 'slide' + index ? '#FFDB1C': '#9A9A9A']"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavSlide',
  data() {
    return {
      currentSlide: 'slide1',
      quantitySlides: 7,
    };
  },
  props: {
    slideIndex: String,
  },
  methods: {
    changeSlide(e) {
      this.currentSlide = e.target.closest('.nav-item').dataset.slide;
      this.$emit('onChangeSlide', this.currentSlide);
    },
    updateNav() {
      this.currentSlide = this.slideIndex;
    },
    // nextSlide() {
    //   const elActive = this.$refs.navigation.querySelector('.nav-item-active');
    //   elActive.nextElementSibling.classList.add('nav-item-active');
    //   elActive.classList.remove('nav-item-active');
    //   this.currentSlide = elActive.nextElementSibling.dataset.slide;
    //   this.$emit('onChangeSlide', this.currentSlide);
    // },
    // previousSlide() {
    //   const elActive = this.$refs.navigation.querySelector('.nav-item-active');
    //   elActive.previousElementSibling.classList.add('nav-item-active');
    //   elActive.classList.remove('nav-item-active');
    //   this.currentSlide = elActive.previousElementSibling.dataset.slide;
    //   this.$emit('onChangeSlide', this.currentSlide);
    // },
  },
  watch: {
    slideIndex: {
      handler(val) {
        this.currentSlide = val;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/styles/nav-slider.scss';

</style>
