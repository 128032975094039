<template>
  <div class="wrapper">
    <div class="contacts-wrapper">
      <div class="contacts" data-aos="fade-up">
        <h1 class="contacts__title">контакты</h1>
        <p class="contacts__name-address">АО «Росгеология»</p>
        <p class="contacts__text-address">
          <span>Адрес:</span> 117246, Россия, г. Москва, Херсонская улица, д. 43, корп. 3, бизнес-центр «Газойл Сити»
        </p>
        <p class="contacts__phone">
          <span>Тел.:</span> <a href="tel:+74959885807">+7 (495) 988-58-07</a>, <a href="tel:+74959885809">+7 (495) 988-58-09</a>
        </p>
        <p class="contacts__fax">
          <span>Факс:</span> <a href="tel:+74992719760">+7 (499) 271-97-60</a>
        </p>
        <h2 class="contacts__network-title">Мы в социальных сетях:</h2>
        <div class="contacts__networks">
          <!-- <a href="https://www.facebook.com/rusgeology" target="_blank">
            <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.25168 10.3335V19L7.14022 18.9989V10.332H10.3829L10.8706 6.95448H7.14063V4.79846C7.14063 3.82086 7.44222 3.15426 9.0056 3.15426L11 3.15352V0.132656C10.6553 0.0924907 9.47318 0 8.09469 0C5.21937 0 3.25168 1.57455 3.25168 4.46461V6.95559H0V10.3335H3.25168Z" fill="white"/>
            </svg>
          </a> -->
          <a href="https://vk.com/rusgeology" target="_blank">
            <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.2128 7.63019C17.8762 8.31695 18.5764 8.96303 19.1715 9.71904C19.4343 10.055 19.6831 10.4017 19.8735 10.7915C20.1433 11.3458 19.899 11.9557 19.4303 11.9887L16.5166 11.9873C15.7651 12.0534 15.1656 11.7327 14.6616 11.1879C14.2582 10.7523 13.8846 10.2886 13.4967 9.83827C13.3377 9.65422 13.1712 9.48102 12.9724 9.3441C12.5746 9.07038 12.2294 9.15417 12.0021 9.594C11.7706 10.0414 11.718 10.5367 11.6953 11.0353C11.6641 11.7627 11.4567 11.954 10.7675 11.9873C9.29469 12.0609 7.89689 11.8247 6.59834 11.0369C5.45349 10.3424 4.56576 9.36199 3.79303 8.25209C2.28862 6.09089 1.13647 3.71603 0.101008 1.27465C-0.132077 0.724611 0.038391 0.429357 0.610811 0.418879C1.56132 0.399289 2.5117 0.400698 3.46334 0.417471C3.84967 0.423476 4.10541 0.658387 4.25459 1.04533C4.76885 2.386 5.3981 3.66153 6.18795 4.84382C6.3983 5.15858 6.61279 5.47338 6.91818 5.69492C7.25607 5.94022 7.51333 5.85892 7.67228 5.45984C7.77314 5.20679 7.81732 4.93423 7.84005 4.66325C7.91536 3.73086 7.92528 2.80004 7.79322 1.87092C7.71212 1.29105 7.40408 0.915661 6.85833 0.805951C6.57986 0.749998 6.6213 0.640122 6.75615 0.471601C6.99033 0.180946 7.2106 0 7.6497 0H10.9426C11.461 0.108468 11.5761 0.355389 11.6471 0.908414L11.6499 4.78646C11.6442 5.00054 11.7507 5.6359 12.1145 5.77758C12.4055 5.87847 12.5974 5.63155 12.7721 5.43586C13.5605 4.54869 14.1232 3.50021 14.6259 2.41454C14.849 1.93714 15.0408 1.44139 15.2267 0.946019C15.3644 0.578454 15.5806 0.397591 15.9711 0.405585L19.1403 0.408566C19.2343 0.408566 19.3294 0.410016 19.4204 0.426541C19.9544 0.52304 20.1008 0.766606 19.9358 1.31951C19.6759 2.18696 19.1703 2.90987 18.6758 3.6361C18.1473 4.41144 17.5821 5.16028 17.058 5.94022C16.5764 6.65257 16.6146 7.01156 17.2128 7.63019Z" fill="white"/>
            </svg>
          </a>
          <!-- <a href="https://www.instagram.com/rusgeology/" target="_blank">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5615 0H3.43846C1.5 0 0 1.5 0 3.43846V7.13077V14.5615C0 16.5 1.5 18 3.43846 18H14.5615C16.5 18 18 16.5 18 14.5615V7.13077V3.43846C18 1.5 16.5 0 14.5615 0ZM12.8769 2.03077H15.5538L15.9231 2.00769V2.42308V5.1H12.8769V2.03077ZM6.41538 7.13077C6.99231 6.32308 7.93846 5.79231 9 5.79231C10.0615 5.79231 11.0077 6.32308 11.5846 7.13077C11.9538 7.66154 12.1846 8.30769 12.1846 9C12.1846 10.7538 10.7538 12.1846 9 12.1846C7.24615 12.1846 5.81538 10.7538 5.81538 9C5.81538 8.30769 6.04615 7.66154 6.41538 7.13077ZM16.3846 14.5615C16.3846 15.5308 15.5308 16.3846 14.5615 16.3846H3.43846C2.46923 16.3846 1.61538 15.5308 1.61538 14.5615V7.15385H4.40769C4.17692 7.61539 4.03846 8.33077 4.03846 9C4.03846 11.7462 6.25385 13.9615 9 13.9615C11.7462 13.9615 13.9615 11.7231 13.9615 9C13.9615 8.35385 13.8231 7.61539 13.5923 7.15385H16.3846V14.5615Z" fill="white"/>
            </svg>
          </a> -->
          <a href="https://www.youtube.com/c/rusgeology" target="_blank">
            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.9938 0C9.9938 0 3.74298 5.80068e-06 2.17411 0.407776C1.33408 0.642549 0.642384 1.33444 0.40767 2.18704C9.28809e-06 3.75632 0 7.00618 0 7.00618C0 7.00618 9.28809e-06 10.2684 0.40767 11.813C0.642384 12.6656 1.32173 13.3451 2.17411 13.5799C3.75534 14 9.9938 14 9.9938 14C9.9938 14 16.257 14 17.8258 13.5922C18.6782 13.3574 19.3576 12.6903 19.5799 11.8253C19.9999 10.2684 20 7.01854 20 7.01854C20 7.01854 20.0123 3.75632 19.5799 2.18704C19.3576 1.33444 18.6782 0.654922 17.8258 0.432502C16.257 1.976e-05 9.9938 0 9.9938 0ZM8.00489 4.00359L13.2057 7.00618L8.00489 9.99641V4.00359Z" fill="white"/>
            </svg>
          </a>
          <a href="https://zen.yandex.ru/id/5e3d7600b72e851477372d23" target="_blank">
            <svg  width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
              <path fill="white"
                    d="M16.7 16.7c-2.2 2.27-2.36 5.1-2.55 11.3 5.78 0 9.77-.02 11.83-2.02 2-2.06 2.02-6.24 2.02-11.83-6.2.2-9.03.35-11.3 2.55M0 14.15c0 5.59.02 9.77 2.02 11.83 2.06 2 6.05 2.02 11.83 2.02-.2-6.2-.35-9.03-2.55-11.3-2.27-2.2-5.1-2.36-11.3-2.55M13.85 0C8.08 0 4.08.02 2.02 2.02.02 4.08 0 8.26 0 13.85c6.2-.2 9.03-.35 11.3-2.55 2.2-2.27 2.36-5.1 2.55-11.3m2.85 11.3C14.5 9.03 14.34 6.2 14.15 0c5.78 0 9.77.02 11.83 2.02 2 2.06 2.02 6.24 2.02 11.83-6.2-.2-9.03-.35-11.3-2.55">
              </path>
              <path fill="transparent"
                    d="M28 14.15v-.3c-6.2-.2-9.03-.35-11.3-2.55-2.2-2.27-2.36-5.1-2.55-11.3h-.3c-.2 6.2-.35 9.03-2.55 11.3-2.27 2.2-5.1 2.36-11.3 2.55v.3c6.2.2 9.03.35 11.3 2.55 2.2 2.27 2.36 5.1 2.55 11.3h.3c.2-6.2.35-9.03 2.55-11.3 2.27-2.2 5.1-2.36 11.3-2.55">
              </path>
            </svg>
          </a>
        </div>
      </div>
      <div class="right-side" data-aos="flip-right">
        <div class="link">
          <a href="https://rosgeo.com/" target="blanc" class="contacts__button">
            <p>Перейти на основной сайт</p>
            <span class="arrow"></span>
          </a>
        </div>
      </div>
      <div class="link-mob">
          <a href="https://rosgeo.com/" target="blanc" class="contacts__button">
            <p>Перейти на основной сайт</p>
            <span class="arrow"></span>
          </a>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EighthSlide',
  metaInfo: {
    title: 'День геолога',
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/styles/pages/eight-slide.scss';
</style>
