<template>
  <div id="app">
    <MainSlider />
  </div>
</template>

<script>
import MainSlider from './components/MainSlider.vue';

export default {
  name: 'App',
  components: {
    MainSlider,
  },
  metaInfo: {
    title: 'День геолога',
  },
};
</script>

<style lang="scss">
@import './assets/styles/reset-default-styles.scss';
@import './assets/styles/fonts.scss';
@import './assets/styles/global-styles.scss';
@import './assets/styles/sliders.scss';
</style>
