<template>
  <div>
    <div v-if="!mobVersion" class="main-wrapper">
      <swiper
        @slideChange="setCurrentSlide"
        :options="swiperOptions"
        class="mainSlider"
        ref="mainSlider"
      >
        <swiper-slide>
          <FirstSlide ref="slide1" @goTo="goToTest" id="slide-1" />
        </swiper-slide>
        <swiper-slide>
          <SecondSlide ref="slide2" id="slide-2" />
        </swiper-slide>
        <!-- <swiper-slide>
          <ThirdSlide ref="slide3" id="slide-3" />
        </swiper-slide> -->
        <swiper-slide>
          <FourthSlide ref="slide3" id="slide-3" />
        </swiper-slide>
        <swiper-slide>
          <FifthSlide ref="slide4" />
        </swiper-slide>
        <swiper-slide>
          <SixthSlide ref="slide5" id="slide-5" />
        </swiper-slide>
        <!-- <swiper-slide>
            <SixthSlide ref="slide6" />
          </swiper-slide> -->
        <swiper-slide>
          <EightsSlide ref="slide6" id="slide-6" />
        </swiper-slide>
        <swiper-slide>
          <NinthSlide ref="slide7" id="slide-7" />
        </swiper-slide>
      </swiper>
      <NavSlider :slideIndex="currentSlide" @onChangeSlide="changeSlide" />
    </div>
    <div v-else class="main-wrapper">
      <FirstSlide @goTo="goToTest" />
      <SecondSlide />
<!--      <ThirdSlide />-->
      <FourthSlide />
      <FifthSlide />
      <SixthSlide />
      <!-- <SixthSlide /> -->
      <EightsSlide />
      <NinthSlide />
    </div>
  </div>
</template>

<script>
import { getQueryStringValue } from "@/helpers/functions";

import { Swiper as SwiperClass } from "swiper/js/swiper.esm";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";

import FirstSlide from "./FirstSlide.vue";
import SecondSlide from "./SecondSlide.vue";
// import ThirdSlide from "./ThirdSlide.vue";
import FourthSlide from "./FourthSlide.vue";
import FifthSlide from "./FifthSlide.vue";
import SixthSlide from "./SixthSlide.vue";
// import SixthSlide from './SixthSlide.vue';
import EightsSlide from "./EightsSlide.vue";
import NinthSlide from "./NinthSlide.vue";
import NavSlider from "./NavSlider.vue";

const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);

export default {
  name: "MainSlider",
  components: {
    NavSlider,
    FirstSlide,
    SecondSlide,
    // ThirdSlide,
    FourthSlide,
    SixthSlide,
    FifthSlide,
    // SixthSlide,
    EightsSlide,
    NinthSlide,
    // Hooper,
    // Slide,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        direction: "vertical",
        slidesPerView: 1,
        mousewheel: {
          eventsTarget: ".swiper-container:not(.slide-text__descr), ",
          forceToAxis: true,
        },
        navigation: false,
        speed: 800,
        allowTouchMove: false,
        on: {
          slideChangeTransitionStart: () => {
            this.initAos();
          },
        },
      },
      mobVersion: false,
      currentSlide: "slide1",
      scroll: 0,
      userWidth: 0,
    };
  },
  async mounted() {
    let slide = getQueryStringValue("slide");
    slide = parseInt(slide, 10);
    if (Number.isNaN(slide)) slide = 0;
    this.changeSlide(slide);
    setTimeout(() => {
      this.initAos();
    }, 800);
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    initAos() {
      const wrapper = this.$refs[this.currentSlide] && this.$refs[this.currentSlide].$el;
      if (!wrapper) return;
      const elements = wrapper.querySelectorAll("[data-aos]");
      elements.forEach((item) => {
        if (!item.classList.contains("aos-animate")) {
          item.classList.add("aos-animate");
        }
      });
    },
    changeSlide(i) {
      if (!this.mobVersion) {
        this.$refs.mainSlider.$swiper.slideTo(i - 1, 800);
        this.setCurrentSlide(i);
      } else {
        const scrollTo = document.querySelector(`#slide-${i}`);
        console.log(`#slide-${i}`);
        if (scrollTo) {
          setTimeout(() => {
            this.$smoothScroll({
              scrollTo,
              updateHistory: false,
            });
          }, 300);
        }
      }
    },
    getCurrentSlide(obj) {
      this.currentSlide = `slide${obj.currentSlide + 1}`;
    },
    setCurrentSlide() {
      // document.querySelectorAll('[data-aos]').removeClass('aos-init').removeClass('aos-animate');
      this.currentSlide = `slide${
        this.$refs.mainSlider.$swiper.activeIndex + 1
      }`;
    },
    goToTest() {
      if (!this.mobVersion) {
        this.changeSlide(6);
      } else {
        this.$smoothScroll({
          scrollTo: document.querySelector("#slide-test"),
          updateHistory: false,
        });
      }
    },
    onChangeWidth() {
      try {
        this.userWidth = window.innerWidth;
      } catch (e) {
        console.log(e);
      }
    },
    handleResize() {
      this.onChangeWidth();
      if (this.userWidth <= 992) {
        this.mobVersion = true;
      } else {
        this.mobVersion = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/main-slider.scss";
</style>
