<template>
  <div class="wrapper" id="slide-4">
    <div class="persons-slider">
      <agile :options='options'>
        <div v-for="(person, index) in employees" :key="index" class="slide-person">
          <div class="slide-text">
            <h1 class="slide-text__title" data-aos="fade">
              Истории сотрудников росгеологии
            </h1>
            <div class="slide-text__position">
              <img class="slide-text__icon" :src="person.positionIcon" data-aos="fade">
              <div class="slide-text__name" data-aos="fade">
                <h2>{{ person.name }}</h2>
                <p>{{ person.position }}</p>
              </div>
            </div>
            <div class="slide-text__descr" data-aos="fade">
              <span>«</span>{{ person.text }}<span>»</span>
            </div>
          </div>
          <div class="slide-photo" data-aos="fade">
            <img :src="person.img">
          </div>
        </div>
        <template slot="nextButton">
          <p>листать дальше</p>
          <span class="arrow"></span>
        </template>
        <template slot="prevButton">
          <span class="arrow">
            <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.08597 1.39759L1.40241 7.41271L8.08594 13.428" stroke="#C8C8C8" stroke-width="1.6"/>
            </svg>
          </span>
        </template>
    </agile>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FourthSlide',
  data() {
    return {
      options: {
        slideToShow: 1,
        dots: false,
      },
      employees: [
        {
          img: require('../assets/img/employees/Daria.png'),
          name: 'Ипполитова Дарья',
          position: 'Ведущий гидрогеолог',
          positionIcon: require('../assets/img/positionIcon1.svg'),
          text: ' Могу сказать - если есть профессиональные амбиции, то это к нам.  Колоссальный опыт работы в аналитической, в неорганической, в физической, в физколлоидной химии и не просто в какой-то отдельной операции, как многие лаборатории сейчас делают – ставят одного человека на одну операцию, здесь химики являются универсалами - от простейших анализов до завершения работы. Каждый объект, каждый новый заказ - это задача, решив которую испытываешь профессиональное удовлетворение. ',
        },
        {
          img: require('../assets/img/employees/Ihor.jpg'),
          name: 'Бабкин Игорь',
          position: 'Ведущий геолог, АО «Сибирское ПГО',
          positionIcon: require('../assets/img/positionIcon1.svg'),
          text: 'Я ведущий геолог Восточно-Саянской партии АО «Росгеология». Начал строить свою профессиональную работу с 2012 года в ОАО «Красноярскгеолсъёмка» (ныне АО «Сибирское ПГО»). Работа в геологии позволила мне получить колоссальный опыт, множество необходимых навыков и качеств специалиста. Мне нравится моя работа и благодаря ей я вырос не только в профессиональном плане, но и в социально-моральном аспекте жизни. Росгеология дала мне всё необходимое, чтобы учиться и узнавать всё больше и больше, расти, не только как специалист, но и как личность. Я благодарен за предоставленный мне шанс внести что-то своё, что-то новое в развитие Российской геологии.',
        },
        {
          img: require('../assets/img/employees/Fadeeva.jpeg'),
          name: 'Фадеева Евгения',
          position: 'инженер, АО «НПЦ «Недра»',
          positionIcon: require('../assets/img/positionIcon1.svg'),
          text: ' Мне посчастливилось устроиться в НПЦ Недра и стать частью одного общего и важного дела. На прошлом месте работы у меня были однообразные задачи, а работа здесь дает мне возможность каждый день сталкиваться с новыми и интересными задачами. Благодаря работе в НПЦ Недра, я могу развивать новые профессиональные навыки. Хороший коллектив, всегда помогут и ответят на вопросы, которые у меня возникают во время работы.',
        },
        {
          img: require('../assets/img/employees/Anastasiya.png'),
          name: 'Субботина Анастасия',
          position: 'Юрист, АО «НПЦ «Недра»',
          positionIcon: require('../assets/img/positionIcon1.svg'),
          text: ' Работа юриста в АО «НПЦ «Недра», которое входит в группу компаний Холдинга Росгеология, связана с выполнением множества разноплановых и интересных задач. Для меня был увлекательным опыт подготовки документов для проведения сделки с соблюдением требований и Российского и иностранного законодательства (Республики Казахстан). Работая в одном городе, ты фактически взаимодействуешь с высококлассными специалистами из разных регионов России, которые стремятся к достижению общего результата. Очень приятно ощущать себя частью такой профессиональной и сплоченной команды: Холдинг Росгеология.',
        },
        {
          img: require('../assets/img/employees/Ekaterina.jpg'),
          name: 'Свинкина Екатерина',
          position: 'Геофизик ОСП «Дальгеофизика», АО Дальневосточное ПГО',
          positionIcon: require('../assets/img/positionIcon1.svg'),
          text: 'На Дальний Восток я приехала из Читы после Института по направлению в 2013 году. Новичку всегда сложно, но   тогда поддержали опытные коллеги, помогли адаптироваться. Сейчас в компании вопросы наставничества формируются уже в программное решение, когда за опытными специалистами закрепляют новичков, которым дают не только практические знания в полях, но и подготавливают к дальнейшей аналитической работе, например, написанию отчетов. И это отличный шанс для молодых специалистов быстро и успешно войти в профессию.',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/styles/pages/fourth-slide.scss';
</style>
