module.exports = [
  {
    imgs: [
      require("../assets/img/storys/1/1.jpg"),
      require("../assets/img/storys/1/2.jpg"),
    ],
    slug: "story-1",
    name: "Мария Костина",
    positionIcon: require("../assets/img/storys/1_pos.svg"),
    text:
      `Работа на свежем воздухе предполагает и встречу с теми, кто живет здесь на постоянке. С дикими животными🐻
      <br />
      <br />
      С медведями, наверное, сразу подумаешь ты. Да, медведи любопытные и любят приходить в лагерь в поисках легкой еды. Нет, не людей есть, а отходы с кухни☺️
      <br />
      <br />
      Медведь — зверь умный и с человеком добровольно встречаться не будет, а вот нашкодить, это, пожалуйста.
      <br />
      <br />
      На профиле мишкам нравятся играть с проводами, которые оставляют геофизики. Запутать или порвать в нескольких местах километровый провод — любимая забава босоногих. Весело потом, наверное, наблюдать за геофизиками, которые бегают вдоль провода и ищут разрывы😉
      <br />
      <br />
      Посмотри фильм “Медведи Камчатки. Начало жизни.”, чтобы в безопасности понаблюдать за сильным, быстрым и пластичным зверем🐾
      <br />
      <br />
      Закрой глаза и представь, как подходишь с подветренной стороны к табуну косуль близко близко и они, почуяв тебя слишком поздно, резко вскакивают и уносятся прочь да так, что сам пугаешься внезапности. У меня такое было наяву.
      <br />
      <br />
      Встречаешь на профиле препятствие из наваленных запутанных веток, которое выше тебя раза в два, долго думаешь, как же перебраться через него. В этот момент мимо тебя пробегает косуля и за секунду перепрыгивает бурелом, который позже штурмуешь полчаса🦘
      <br />
      <br />
      Птица, которая только что прихрамывала и не могла лететь, взлетает в одно мгновение. Увела человека от гнезда с птенцами, молодец🦃
      <br />
      <br />
      Зайцы выскакивали из-под моих ног так часто, что рабочие устали от испуганных вскриков. Большие зайцы, оказывается, толстенькие🐰
      <br />
      <br />
      Увидела филина, который сидел на дереве, а он меня и как повернул голову на 180 градусов. Взаправду он так может, это не только в книгах написано🦉
      <br />
      <br />
      Бурундуки, евражки, полёвки, горностаи: все это бегает, шуршит, стрекочет, щебечет. Каждый шаг может принести новую встречу, держи фотоаппарат ближе📸`,
  },
  {
    imgs: [require("../assets/img/storys/2/1.jpg"), require("../assets/img/storys/2/2.jpg")],
    name: "Мария Костина",
    slug: "story-2",
    positionIcon: require("../assets/img/storys/1_pos.svg"),
    text:
      `Ох, тяжёлая эта работа, танк тащить из болота.
      <br />
      <br />
      Титаник шел ко дну, а вместе с ним и надежда до темноты оказаться в лагере.
      <br />
      <br />
      Еще 5 минут назад мы с радостным чувством “Домой” мчались на нашем бронированном друге, уворачивались от веток и предвкушали ужин, баню и сон под треск дров в печке-буржуйке.
      <br />
      <br />
      Хотя нет, эта история началась еще раньше.
      <br />
      <br />
      Несколько дней в верховьях реки, на которой стоял лагерь, шли дожди, и река прежде глубиной не выше колена превратилась в бушующий горный поток.
      <br />
      <br />
      Утром того дня МТЛБ как амфибия, задраив люки, плыл вверх по реке, чтобы доставить работников на профиля: рубщиков, геологов, геофизиков. Вечером, собрав всех обратно, амфибия вышла на сушу, чтобы добраться до лагеря по болотине.
      <br />
      <br />
      В итоге титаник шел ко дну, а вместе с ним и надежда оказаться в лагере до темноты. Мы уходили в пеший вынужденный маршрут и оставляли боевого товарища по люки в болотной жиже.
      <br />
      <br />
      На следующий день два бульдозера вышли в спасательную экспедицию, но, не пройдя и пары сотен метров, спасать пришлось уже их.
      В лагерь пришла вселенская тоска, в маршруты ходили пешком, пробы выносили на себе.
      <br />
      <br />
      Но герой нашелся! Им стал водитель, он же механик и укротитель неповоротливого зверя, который согласился нырнуть в холодную воду и подложить бревна под гусеницы вездехода.
      <br />
      <br />
      А дальше, как принято в России, спасение утопающего дело рук самого утопающего, заработал двигатель и вездеход начал понемногу проворачивать гусеницы, цепляя бревна и подтягивая их под себя. Когда твердой опоры стало достаточно, вездеход вырвался на свободу из лап трясины.
      <br />
      <br />
      Лагерь ликовал, и встречал героя бросанием кепок и шапок вверх, но для полного счастья не хватало огненной воды. Тьфу, ща быстро сгоняем до деревни, всего-то 7 км. Ерунда!
      <br />
      <br />
      Воодушевленные спасением пассажиры усаживались на броню. Вперед, в деревню, только переправимся через все еще большую воду .
      <br />
      <br />
      Тринадцатитонный вездеход вместе с испуганными людьми пролетел мимо лагеря как щепка по волнам. Чудом МТЛБ зацепился за берег и спасся второй раз за день.
      <br />
      <br />
      Лагерь гулял всю ночь, как никак два дня рождения, ну и пусть что, один из них по глупости😊`,
  },
  {
    imgs: [require("../assets/img/storys/3/1.jpg"), require("../assets/img/storys/3/2.jpg")],
    name: "Александра Ковалева",
    slug: "story-3",
    positionIcon: require("../assets/img/storys/1_pos.svg"),
    text:
     `На участке через какое-то время начинаешь радоваться мелочам: макаронам в форме кружочков, красивым облакам, не ледяной воде в «душе», красочным снам, ложке сгущёнки к чаю, тёплым вещам, спутниковому интернету пару раз в сутки, добрым новостям....а вот солнечный день, это просто праздник какой-то, давно у нас их не было.`,
  },
];
