import Vue from 'vue';
import VueAgile from 'vue-agile';
import AOS from 'aos';
import VueMeta from 'vue-meta';
import VueYandexMetrika from 'vue-yandex-metrika';
import VueSmoothScroll from 'vue2-smooth-scroll';
import { Swiper as SwiperClass, Mousewheel, Navigation } from 'swiper/js/swiper.esm';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';

import App from './App.vue';
import 'aos/dist/aos.css';
import 'swiper/css/swiper.css';

Vue.use(getAwesomeSwiper(SwiperClass));
SwiperClass.use([Mousewheel, Navigation]);

Vue.use(VueSmoothScroll);
Vue.use(VueYandexMetrika, {
  id: 74852506,
  debug: process.env.NODE_ENV === 'development',
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  },
});

Vue.use(VueMeta);

Vue.use(VueAgile);

Vue.config.productionTip = false;
new Vue({
  created() {
    AOS.init({
      duration: 800,
    });
  },
  render: (h) => h(App),
}).$mount('#app');
