<template>
  <div class="wrapper">
    <div class="logo">
      <div class="logo__img">
        <img src="../assets/img/logo.svg">
      </div>
    </div>
    <div class="content-wrapper">
      <div class="content">
        <p class="content__day" data-aos="fade-right">ДЕНЬ</p>
        <img class="content__img" src="../assets/img/left-stone.png" data-aos="fade-down">
        <p class="content__geologists" data-aos="fade-right">ГЕОЛОГА</p>
        <div class="content__descr-bg">
        </div>
        <div class="content__descr">
          <div class="content__descr__date">
                <div class="anniversary">
                  55 лет
                </div>
            <p>4 апреля</p>
            <span></span>
          </div>
          <div class="content__descr__title">
            <img src="../assets/img/hammer.svg" alt="">
            День геолога
          </div>
          <p class="content__descr__text">
            День геолога — профессиональный праздник работников геологической отрасли. Учрежден в 1966 году в честь первооткрывателей Западно-Сибирской нефтегазоносной провинции. С тех пор празднуется ежегодно в первое воскресенье апреля.
          </p>
        </div>
        <div class="right-stone">
          <img src="../assets/img/right-stone.png" data-aos="fade-down">
        </div>
          <a class="content__button" @click.prevent="$emit('goTo', true)" href="#testId">
            <p>Узнай, какой ты геолог</p>
            <span class="arrow"></span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FirstSlide',
};
</script>

<style lang="scss" scoped>
@import '../assets/styles/pages/first-slide.scss';
</style>
